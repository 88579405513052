import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { apiConfig } from "config/apiConfig";
import { getAuth0Client, microsoftAuthUrl } from "config/authConfig";
import { AuthRequirement, getAuthRequirement } from "services/graphql/graphqlRoutes";
import { authLoginPopUp } from "utils/authHelper";

export async function getToken(operationName: string | null): Promise<string | null> {
  const authRequirement = getAuthRequirement(operationName);

  // Don't even try to get token for public-only routes
  if (authRequirement === AuthRequirement.NONE) {
    return null;
  }

  try {
    const auth0 = await getAuth0Client();
    const accessToken = await auth0.getTokenSilently();
    return accessToken;
  } catch (error: any) {
    console.error(error);

    // For required auth, try login popup
    if (
      authRequirement === AuthRequirement.REQUIRED &&
      (error.error === "login_required" || error.error === "consent_required")
    ) {
      return await authLoginPopUp(microsoftAuthUrl);
    }

    // For optional auth, just return null on error
    if (authRequirement === AuthRequirement.OPTIONAL) {
      console.log("Optional auth failed, proceeding without authentication");
      return null;
    }

    console.error("Unexpected error during token retrieval:", error);
    return null;
  }
}

const httpLink = createHttpLink({
  uri: apiConfig.platformApiEndpoint,
});

const authLink = setContext(async (operation, { headers }) => {
  const token = await getToken(operation.operationName ?? null);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
