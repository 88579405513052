import { MutationsOperations } from "./mutations/mutationsOperations";
import { QueriesOperations } from "./queries/queriesOperations";

export enum AuthRequirement {
  REQUIRED = "REQUIRED",
  OPTIONAL = "OPTIONAL",
  NONE = "NONE",
}

export type GraphQLOperation = {
  operationName: QueriesOperations | MutationsOperations;
  authRequirement: AuthRequirement;
};

const operationsRoutes: GraphQLOperation[] = [
  //  QUERIES
  {
    operationName: QueriesOperations.GET_QUESTS_BY_CATEGORY,
    authRequirement: AuthRequirement.OPTIONAL,
  },
  {
    operationName: QueriesOperations.GET_CURRENT_ORGANIZATION,
    authRequirement: AuthRequirement.REQUIRED,
  },
  {
    operationName: QueriesOperations.GET_QUEST_BY_SLUG_AND_LANGUAGE,
    authRequirement: AuthRequirement.REQUIRED,
  },
  {
    operationName: QueriesOperations.GET_RECOMMEND_FOR_YOU,
    authRequirement: AuthRequirement.REQUIRED,
  },

  // MUTATIONS
  {
    operationName: MutationsOperations.MARK_PAGE_AS_COMPLETED,
    authRequirement: AuthRequirement.REQUIRED,
  },
  {
    operationName: MutationsOperations.MARK_TASK_AS_COMPLETED,
    authRequirement: AuthRequirement.REQUIRED,
  },
  {
    operationName: MutationsOperations.ENROLL_USER,
    authRequirement: AuthRequirement.REQUIRED,
  },
  {
    operationName: MutationsOperations.UNENROLL_USER,
    authRequirement: AuthRequirement.REQUIRED,
  },
];

const getOperation = (operationName: string | null): GraphQLOperation | undefined =>
  operationName ? operationsRoutes.find((op) => op.operationName === operationName) : undefined;

/**
 * Retrieves the auth requirement status for an operation.
 * @returns The auth requirement: REQUIRED, OPTIONAL, or NONE.
 */
export const getAuthRequirement = (operationName: string | null): AuthRequirement => {
  return getOperation(operationName)?.authRequirement ?? AuthRequirement.NONE;
};
