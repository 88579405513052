import LockedModal from "components/LockedModal";
import Icons from "components/icons/Icons";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavDropdownMenu from "../components/NavDropdownMenu";
import NavDropdownMenuTabletMobile from "../components/NavDropdownMenuTabletMobile";
// import SearchIcon from "../components/icons/SearchIcon";
import MicrosoftLogo from "../components/logo/MicrosoftLogo";
import { WorkwayLogoFull } from "../components/logo/WorkwayLogo";
import WorkwaySmallLogo from "../components/logo/WorkwaySmallLogo";

enum NavLinks {
  Home = "Home",
  Programs = "Programs",
  Meditations = "Meditations",
}

const Navbar = () => {
  const { userAccessState } = useUserState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [userData, setUserData] = useState({
    customizedLogo: "/",
    name: "Stephanie Melon",
    id: "",
    email: "test@mindvalley.com",
    profilePhoto: "https://via.placeholder.com/150",
    smallLogo: "https://via.placeholder.com/150/0000FF",
    role: "",
    isMicrosoftUser: false,
    isAdmin: false,
    adminPanelURL: "/",
    profileURL: "/",
  });

  const location = useLocation();

  const menuHandler = (value = false) => {
    setIsMenuOpen(value);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setIsVisible(false);
      } else {
        // if scroll up show the navbar
        setIsVisible(true);
      }
      menuHandler(false);
      setLastScrollY(window.scrollY);
    }
  };
  const menuRender = () => {
    return [
      { label: NavLinks.Home, link: "/" },
      { label: NavLinks.Programs, link: `/${NavLinks.Programs}` },
      { label: NavLinks.Meditations, link: `/${NavLinks.Meditations}` },
    ];
  };

  const handleClickTabNavigation = ({
    e,
    link,
  }: {
    e: React.MouseEvent<HTMLAnchorElement>;
    link: string;
  }) => {
    if (
      userAccessState !== UserAccessStateEnum.Premium &&
      userAccessState !== UserAccessStateEnum.Freemium &&
      link !== "/"
    ) {
      setIsModalOpen(true);
      e.preventDefault();
    } else {
      console.log(link);
    }
  };

  return (
    <div className="fixed z-[49] top-0 w-screen">
      <div className={cn("navbar", isVisible ? "visible" : "hidden")}>
        <div className="fixed z-10 bg-white/90 w-full flex items-center justify-between py-4 px-4 lg:px-10 border-b lg:border-b-0  border-cool-grey-300 backdrop-blur">
          <div className="flex items-center gap-12">
            <div className="relative flex items-center gap-3">
              <div className="hidden md:block">
                <WorkwayLogoFull width="147.88px" />
              </div>
              <div className="md:hidden">
                <WorkwaySmallLogo />
              </div>
              <div className="w-0.5 py-3 bg-cool-grey-300" />
              <div>
                <MicrosoftLogo />
              </div>
            </div>
            <div className="items-center gap-9 hidden lg:flex">
              {menuRender().map((item) => (
                <Link
                  key={item.label}
                  className={cn(
                    "title-7 transition-colors hover:text-cool-grey-700 data-[selected]:text-cool-grey-700",
                    location.pathname === item.link ? "text-cool-grey-700" : "text-cool-grey-450",
                  )}
                  onClick={(e) => handleClickTabNavigation({ e, link: item.link })}
                  to={item.link}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            {/* <div>
            <SearchIcon />
          </div> */}
            <div className="relative">
              {(userAccessState === UserAccessStateEnum.Premium ||
                userAccessState === UserAccessStateEnum.Freemium) && (
                <div
                  onMouseEnter={() => menuHandler(true)}
                  onClick={() => menuHandler(!isMenuOpen)}
                  onKeyUp={() => menuHandler(false)}
                  onKeyDown={() => menuHandler(false)}
                  onKeyPress={() => menuHandler(!isMenuOpen)}
                >
                  <img
                    className="h-8 w-8 rounded-full cursor-pointer hidden lg:block"
                    src={userData.profilePhoto}
                    alt="user"
                  />
                </div>
              )}
              {(userAccessState === UserAccessStateEnum.Premium ||
                userAccessState === UserAccessStateEnum.Freemium) && (
                <div
                  className="lg:hidden"
                  onClick={() => menuHandler(!isMenuOpen)}
                  onKeyUp={() => menuHandler(false)}
                  onKeyDown={() => menuHandler(false)}
                  onKeyPress={() => menuHandler(!isMenuOpen)}
                >
                  <Icons name={!isMenuOpen ? "menu-filled" : "x-filled"} height={24} width={24} fill="#595E67" />
                </div>
              )}
              {isMenuOpen && (
                <div className="hidden lg:block" onMouseLeave={() => menuHandler(false)}>
                  <NavDropdownMenu userData={userData} setIsMenuOpen={() => setIsMenuOpen(false)} />
                </div>
              )}
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="w-full lg:hidden">
            <NavDropdownMenuTabletMobile
              menuRender={menuRender()}
              userData={userData}
              setIsMenuOpen={() => menuHandler(false)}
            />
          </div>
        )}
      </div>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          isAuthenticated={
            userAccessState === UserAccessStateEnum.Premium || userAccessState === UserAccessStateEnum.Freemium
          }
        />
      </div>
    </div>
  );
};
export default Navbar;
