import { ApolloProvider } from "@apollo/client";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxProvider } from "context/teamsFxContext";
import { UserStateProvider } from "context/userContext";
import AppRoute from "router/router";
import { apolloClient } from "services/graphql/client";
import { authConfig } from "./config/authConfig";
import { teamsConfig } from "./config/teamsConfig";

export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: authConfig.redirect_uri,
    clientId: teamsConfig.clientId,
  });

  return (
    <TeamsFxProvider value={{ theme, themeString, teamsUserCredential }}>
      <ApolloProvider client={apolloClient}>
        <UserStateProvider>
          <AppRoute loading={loading} themeString={themeString} theme={theme} />
        </UserStateProvider>
      </ApolloProvider>
    </TeamsFxProvider>
  );
}
