import LockedModal from "components/LockedModal";
import { LoginWithMicrosoftButton } from "components/LoginWithMicrosoftButton";
import { WorkwayLogoFull } from "components/logo/WorkwayLogo";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useMicrosoftLogin } from "hooks/useMicrosoftLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function HeroHeading() {
  const { userAccessState } = useUserState();
  const navigate = useNavigate();
  const handleLogin = useMicrosoftLogin();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClickGetInTouch() {
    navigate("/interestform");
  }

  return (
    <>
      <div className="grid space-y-2 text-center mb-2">
        <div className="flex flex-col md:flex-row items-center justify-center space-x-2">
          <h1 className="heading-1 relative">Welcome to</h1>

          <div className="w-[60%] md:w-[20%] lg:size-fit">
            <WorkwayLogoFull />
          </div>
        </div>

        <h2 className="heading-2">Where personal growth fuels collective impact.</h2>
      </div>
      <div className="flex flex-col items-center gap-y-2 mt-4">
        {userAccessState === UserAccessStateEnum.Unauthenticated || userAccessState === UserAccessStateEnum.Loading ? (
          <LoginWithMicrosoftButton handleClick={handleLogin} />
        ) : (
          <>
            {" "}
            <div className="title-9">You are now accessing the free version of workway.</div>
          </>
        )}
        <p className="title-9">
          or{" "}
          <button className="underline text-red-600" onClick={handleClickGetInTouch} type="button">
            get in touch
          </button>{" "}
          with our sales team
        </p>
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          isAuthenticated={
            userAccessState === UserAccessStateEnum.Premium || userAccessState === UserAccessStateEnum.Freemium
          }
        />
      </div>
    </>
  );
}
