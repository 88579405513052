import { getAuth0Client } from "config/authConfig";
import { useCallback } from "react";

export function useLogout() {
  const logout = useCallback(async () => {
    const auth0 = await getAuth0Client();

    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("userProfile");

    auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, []);

  return logout;
}
