import { gql } from "@apollo/client";
import { MutationsOperations } from "./mutationsOperations";

export const MARK_PAGE_AS_COMPLETED = gql`
  mutation ${MutationsOperations.MARK_PAGE_AS_COMPLETED}($input: MarkPageAsCompletedInput!) {
    markPageAsCompleted(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;

export const MARK_TASK_AS_COMPLETED = gql`
  mutation ${MutationsOperations.MARK_TASK_AS_COMPLETED}($input: MarkTaskAsCompletedInput!) {
    markTaskAsCompleted(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;

export const ENROLL_USER = gql`
  mutation ${MutationsOperations.ENROLL_USER}($input: EnrollUserInput!) {
    enrollUser(input: $input) {
      successful
      messages {
        message
        code
      }
    }
  }
`;

export const UNENROLL_USER = gql`
  mutation ${MutationsOperations.UNENROLL_USER}($input: UnenrollUserInput!) {
    unenrollUser(input: $input) {
      successful
      result {
        quest {
          id
          slug
          language
        }
        user {
          id
        }
      }
    }
  }
`;
