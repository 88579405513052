import { useQuery } from "@apollo/client";
import gqlQuery from "services/graphql/queries";
import type { ISpokenLanguage } from "types/interfaces";

function useLanguages(first = 1000) {
  const { data, loading, error } = useQuery(gqlQuery.languages, {
    variables: {
      first,
    },
  });

  const langData: ISpokenLanguage[] = data?.languages?.edges?.map((edge: { node: ISpokenLanguage }) => edge.node);

  return { langData, loading, error };
}

export default useLanguages;
