import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Programs.css";
import { Drawer, DrawerContent, DrawerTrigger } from "components/ui/drawer";
import useCategories from "hooks/useCategories";
import useLanguages from "hooks/useLanguages";
import useQuest from "hooks/useQuest";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";
import { useLocation } from "react-router-dom";
import { QuestCategoryEnum } from "types/interfaces";
import type { ICategoryNode, IOption, ISpokenLanguage } from "types/interfaces";
import CategoryContent from "./CategoryContent";
import { Menu } from "./components/Menu";
import { ProgramsHeroContainer } from "./components/ProgramsHeroContainer";

const MenuDrawer = ({
  children,
  trigger,
}: {
  children: JSX.Element;
  trigger: JSX.Element;
}) => {
  return (
    <Drawer>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent>
        <div className="md:mx-16">{children}</div>
      </DrawerContent>
    </Drawer>
  );
};

export default function Programs() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<QuestCategoryEnum>(QuestCategoryEnum.Career);
  const { questData } = useQuest(selectedTab);
  const { categoriesData } = useCategories();
  const { langData } = useLanguages();

  const categoryView = useRef<HTMLDivElement>(null);

  const screenType = useScreenType();

  const [laguageSelected, setLanguageSelected] = useState<ISpokenLanguage>();
  useEffect(() => {
    if (!langData) return;
    setLanguageSelected(langData?.find((lang) => lang.iso2Code === "EN"));
  }, [langData]);

  const category = useMemo(
    () => categoriesData.find((cat: ICategoryNode) => cat.id === selectedTab),
    [categoriesData, selectedTab],
  );

  const languageOptions = useMemo(
    () =>
      langData?.map((lang) => ({
        value: lang.iso2Code ?? "",
        name: lang.displayName ?? "",
      })),
    [langData],
  );

  const bannerImage = useMemo(() => {
    return category?.assets?.banners[screenType.isDesktop ? "desktop" : screenType.isMobile ? "mobile" : "tablet"];
  }, [category, screenType]);

  useEffect(() => {
    if (location.state?.subPageId) {
      setSelectedTab(location.state.subPageId as QuestCategoryEnum);
    }
  }, [location]);

  const onCategorySelect = useCallback(
    debounce((category: ICategoryNode) => {
      setSelectedTab(category.id as QuestCategoryEnum);
      categoryView.current?.scrollIntoView({ behavior: "smooth" });
    }, 300),
    [],
  );

  const onLanguageSelect = (language: IOption) => {
    const lang = langData.find((lng) => lng.iso2Code === language.value);
    setLanguageSelected(lang);
  };

  return (
    <div className="flex flex-col mt-20">
      <ProgramsHeroContainer categoriesData={categoriesData} onCategoryClick={onCategorySelect} />

      <div
        className="hidden lg:flex flex-wrap pl-5 border-y border-cool-grey-200 border-md gap-9 sticky top-0 bg-white z-40 w-full"
        data-testid="category-sticky-tabs-section"
      >
        {categoriesData.map((programCategory: ICategoryNode) => (
          <button
            onClick={() => setSelectedTab(programCategory.id as QuestCategoryEnum)}
            key={programCategory.id}
            type="button"
            className={cn(
              programCategory.id === category?.id ? "border-b-2 border-red-300" : "",
              programCategory.name,
              "flex pt-5 pb-5 hover:border-b-2 hover:border-cool-grey-300",
            )}
          >
            <span className="heading-8 opacity-80">{programCategory.name.toLowerCase()}</span>
          </button>
        ))}
      </div>

      <aside className="flex lg:hidden py-2 sticky top-0 left-0 bg-white px-3 z-40">
        <div className="w-full rounded-lg border border-md border-cool-grey-200 gap-1 flex">
          <MenuDrawer
            trigger={
              <button type="button" className="flex flex-col w-1/2 px-3 py-2 items-center">
                <div>
                  <h2 className="heading-9 text-cool-grey-400 uppercase">By Category</h2>
                  <h3 className={cn(category?.name.toLowerCase(), "heading-8 mt-0.5 lowercase")}>
                    <span>{category?.name}</span>
                  </h3>
                </div>
              </button>
            }
          >
            <Menu
              currentOption={{
                name: category?.name ?? "",
                value: category?.id ?? "",
              }}
              title="Category"
              options={categoriesData.map((cat) => ({
                value: cat.id,
                name: cat.name.toLowerCase(),
              }))}
              onSelectOption={(option: IOption) => {
                const category = categoriesData.find((cat) => cat.id === option.value);
                if (category) onCategorySelect(category);
              }}
            />
          </MenuDrawer>

          <MenuDrawer
            trigger={
              <button
                type="button"
                className="relative flex items-center flex-col w-1/2 px-3 py-2 before:inline-block before:h-[calc(100%-16px)] before:bg-black-12a before:w-px before:absolute before:left-0 justify-center"
              >
                <div>
                  <h2 className="heading-9 text-cool-grey-400 uppercase">By Language</h2>
                  <h3 className="title-9 text-cool-grey-600 mt-0.5">{laguageSelected?.displayName}</h3>
                </div>
              </button>
            }
          >
            <Menu
              currentOption={{
                value: laguageSelected?.iso2Code ?? "",
                name: laguageSelected?.displayName ?? "",
              }}
              title="Language"
              options={languageOptions}
              onSelectOption={onLanguageSelect}
            />
          </MenuDrawer>
        </div>
      </aside>

      <div className="px-3 mt-8" ref={categoryView}>
        {category && questData && (
          <CategoryContent
            id={category.id}
            category={category}
            bannerImage={bannerImage}
            title={category.name.toLowerCase()}
            headline={category.title}
            description={category.description}
            questData={questData}
          />
        )}
      </div>
    </div>
  );
}
