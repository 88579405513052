import { microsoftAuthUrl } from "config/authConfig";
import { authLoginPopUp } from "utils/authHelper";

export function useMicrosoftLogin() {
  async function handleLogin() {
    try {
      await authLoginPopUp(microsoftAuthUrl);
      window.location.reload();
    } catch (error) {
      console.warn("Error during login:", error);
    }
  }

  return handleLogin;
}
