import { useQuery } from "@apollo/client";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { useMemo } from "react";
import gqlQuery from "services/graphql/queries";
import type { ICategoryEdge, ICategoryNode, ICategoryNodeAdditionalInfo, QuestCategoryEnum } from "types/interfaces";
import { questBanners } from "../services/cdn/questsPageBanners";
import prodAdditionalInfo from "../services/mocks/category_node_additional_info/prod.json";
import freeCategories from "../services/mocks/freeCategories.json";

function useCategories() {
  const { userAccessState } = useUserState();
  const additionalInfo: ICategoryNodeAdditionalInfo = prodAdditionalInfo;

  const { loading, error, data } = useQuery(gqlQuery.getCategories, {
    skip: userAccessState !== UserAccessStateEnum.Premium && userAccessState !== UserAccessStateEnum.Freemium,
  });

  const categoriesData = useMemo<ICategoryNode[]>(() => {
    const sourceData = data || freeCategories.data;

    return (
      sourceData?.categories?.edges?.map((edge: ICategoryEdge) => {
        const additional = additionalInfo[edge.node.id] || {
          title: "",
          description: "",
        };
        const name = edge.node.name.replace(/-B2B/g, "").trim();
        return {
          id: edge.node.id,
          name,
          assets: {
            banners: questBanners[edge.node.id as QuestCategoryEnum],
          },
          title: additional.title,
          description: additional.description,
          color: additional.color,
          pattern: additional.pattern,
        };
      }) || []
    );
  }, []);

  return { categoriesData, loading, error };
}

export default useCategories;
