import { gql } from "@apollo/client";
import { QueriesOperations } from "services/graphql/queries/queriesOperations";

export const GET_CURRENT_ORGANIZATION = gql`
  query ${QueriesOperations.GET_CURRENT_ORGANIZATION} {
    currentOrganization {
      name
      division
      logoFullUrl
      logoSquareUrl
    }
  }
`;

export const GET_QUESTS_BY_CATEGORY = gql`
  query ${QueriesOperations.GET_QUESTS_BY_CATEGORY} (
    $category: ID
    $membershipFilter: QuestMembershipFilterValues = ALL
    $first: Int = 6
  ) {
    quests(category: $category, membership: $membershipFilter, first: $first) {
      edges {
        node {
          id
          description
          name
          type
          url
          slug
          language
          trailerAsset {
            url
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            url
            thumbnailUrl
          }
          resources {
            mediaAsset {
              url
              edgeUrl
              contentType
            }
          }
          releases {
            id
            status
            perpetual
            publishedAt
          }
          authors {
            name
          }
          userProgress {
            completed
            totalDays
            totalDaysCompleted
            daysCompleted
            totalLessonsCompleted
            totalLessons
            started
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_QUEST_BY_SLUG_AND_LANGUAGE = gql`
  query ${QueriesOperations.GET_QUEST_BY_SLUG_AND_LANGUAGE}($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      description
      slug
      language
      currentAssignment {
        startDate
        endDate
      }
      type
      enrollmentsCount
      publishedAt
      duration
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      userProgress {
        completed
        totalDays
        totalDaysCompleted
        daysCompleted
        totalLessonsCompleted
        totalLessons
        started
        resumePage {
          id
          name
          duration
          position
          url
        }
      }
      wordmarkAsset {
        url
      }
      owned
      pages {
        id
        name
        type
        groupName
        position
        locked
        completed
        duration
        preview
        nextPage {
          id
        }
        url
        coverAsset {
          thumbnailUrl
          url
        }
        tasks {
          id
          type
          name
          description
          position
          required
          imageUrl
          completed
          coverAsset {
            url
          }
        }
        sections {
          info {
            body
            title
          }
          type
          position
          media {
            type
            author {
              name
            }
            coverAsset {
              url
              edgeUrl
            }
            id
            description
            mediaAsset {
              url
              contentType
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            title
          }
          primaryAsset {
            contentType
            url
            duration
            sizeOfFile
            edgeUrl
            filesize
            id
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
            markers {
              id
              name
              status
              time
            }
            name
            status
            thumbnailUrl
            userProgress {
              watchProgress
              watchTime
              watched
            }
            renditions {
              url
              contentType
            }
          }
        }
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        url
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions {
          id
          contentType
          url
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        isPartOfMembership
        type
        id
        title
        author {
          name
        }
        mediaAsset {
          contentType
          url
          duration
          sizeOfFile
        }
        totalDuration
        description
        coverAsset {
          thumbnailUrl
          url
        }
      }
    }
  }
`;

export const GET_RECOMMEND_FOR_YOU = gql`
  query ${QueriesOperations.GET_RECOMMEND_FOR_YOU} {
    recommendedB2bQuestsPickedForYou(first: 3) {
      edges {
        node {
          quest {
            slug
            language
            authors {
              name
            }
            coverAsset {
              thumbnailUrl
              url
            }
            description
            id
            name
            pages {
              position
              sections {
                primaryAsset {
                  contentType
                  edgeUrl
                  url
                }
              }
            }
            type
            url
            wordmarkAsset {
              url
            }
            categories {
              name
            }
          }
        }
      }
    }
  }
`;
