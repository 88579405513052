import { useQuery } from "@apollo/client";
import { GET_QUEST_BY_SLUG_AND_LANGUAGE } from "services/graphql/queries/index";
import type { IQuest } from "types/interfaces";

function useQuestBySlugAndLanguage(slug: string, language: string) {
  const { loading, error, data, refetch } = useQuery(GET_QUEST_BY_SLUG_AND_LANGUAGE, {
    variables: {
      slugAndLanguage: {
        slug,
        language,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    data: data?.b2bQuestBySlugAndLanguage as IQuest,
    loading,
    error,
    refetch,
  };
}

export default useQuestBySlugAndLanguage;
