import { useQuery } from "@apollo/client";
import { useUserState } from "context/userContext";
import { GET_RECOMMEND_FOR_YOU } from "services/graphql/queries/index";

export function useRecommendForYou() {
  const { isAuthenticated } = useUserState();

  const { loading, data, error } = useQuery(GET_RECOMMEND_FOR_YOU, {
    skip: !isAuthenticated,
  });

  return {
    loading,
    data,
    error,
  };
}
