import { loadConfig } from "utils/loadEnv";

export const ENV_ENUMS = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
};

export const teamsConfig = {
  clientId: loadConfig("REACT_APP_CLIENT_ID"),
  appId: loadConfig("REACT_APP_ID"),
};
