import { Spinner } from "@fluentui/react-components";
import type { Theme } from "@fluentui/react-components";
import Layout from "layouts/Layout";
import { ScrollToHashElement } from "libs/scrollToHashElement";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Index from "tabs/Index";
import HomeTab from "tabs/Home/Home";
import CompanyNameForm from "tabs/HubspotForm/CompanyNameForm";
import InterestForm from "tabs/HubspotForm/InterestForm";
import MeditationsTab from "tabs/Meditations/Meditations";
import { Profile, TabPage } from "tabs/Profile/Profile";
import ProgramsTab from "tabs/Programs/Programs";
import QuestConsumption from "tabs/Quest/QuestConsumption/QuestConsumption";
// import ShareToMeeting from "tabs/Config/ShareToMettingConfig";
import AppInMeetingView from "tabs/Sidepanel/AppInMeeting";
import MeditationSeriesDetailsSidePanelView from "tabs/Sidepanel/views/MeditationSeriesDetailsSidePanelView";
import StageView from "tabs/StageView";
import Configure from "utils/Configure";
import { NavigationEnum } from "./navigationType";

export default function AppRoute({
  loading,
  themeString,
  theme,
}: {
  loading: any;
  themeString: string;
  theme?: Theme;
}) {
  return (
    <>
      {loading ? (
        <Spinner style={{ margin: 100 }} />
      ) : (
        <BrowserRouter>
          <ScrollToHashElement />
          <Layout themeString={themeString} theme={theme}>
            <Routes>
              {/* <Route path="/" element={<Index />} /> */}
              {/* <Route path="/" element={<ShareToMeeting />} /> */}
              <Route path={NavigationEnum.Home} element={<HomeTab />} />
              <Route path={NavigationEnum.Configure} element={<Configure />} />
              <Route path={NavigationEnum.Programs} element={<ProgramsTab />} />
              <Route path={NavigationEnum.Meditations} element={<MeditationsTab />} />
              <Route path={NavigationEnum.StageView} element={<StageView />} />
              <Route path={NavigationEnum.AppInMeeting} element={<AppInMeetingView />} />
              <Route
                path={`${NavigationEnum.AppInMeeting}/meditationSeriesSidePanelView/:slug`}
                element={<MeditationSeriesDetailsSidePanelView />}
              />
              <Route path={NavigationEnum.QuestConsumption}>
                <Route path={":lang/:slug"} element={<QuestConsumption />} />
                <Route path={":lang/:slug/:groupId/:pageId"} element={<QuestConsumption />} />
                <Route path={":lang/:slug/:groupId/:pageId/:tmp"} element={<QuestConsumption />} />
              </Route>
              <Route path={NavigationEnum.Account} element={<Profile page={TabPage.MyAccount} />}>
                <Route path={"/account/:section"} element={<Profile page={TabPage.MyAccount} />} />
              </Route>
              <Route path={NavigationEnum.Library} element={<Profile page={TabPage.MyLibrary} />}>
                <Route path={"/library/:section"} element={<Profile page={TabPage.MyLibrary} />} />
              </Route>
              <Route path={"/interestform"} element={<InterestForm />} />
              <Route path={"/companyform"} element={<CompanyNameForm />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      )}
    </>
  );
}
