import { useMutation } from "@apollo/client";
import { ENROLL_USER, MARK_PAGE_AS_COMPLETED, MARK_TASK_AS_COMPLETED, UNENROLL_USER } from "services/graphql/mutations";

function useQuestConsumption() {
  const [enrollUser, { data: enrollUserResponse, loading: enrollUserLoading, error: enrollUserError }] =
    useMutation(ENROLL_USER);
  const [unEnrollUser, { data: unEnrollUserResponse, loading: unEnrollUserLoading, error: unEnrollUserError }] =
    useMutation(UNENROLL_USER);

  const [
    markPageAsCompleted,
    { data: markPageAsCompletedResponse, loading: markPageAsCompletedLoading, error: markPageAsCompletedError },
  ] = useMutation(MARK_PAGE_AS_COMPLETED);

  const [
    markTaskAsCompleted,
    { data: markTaskAsCompletedResponse, loading: markTaskAsCompletedLoading, error: markTaskAsCompletedError },
  ] = useMutation(MARK_TASK_AS_COMPLETED);

  return {
    enrollUser: {
      enrollUser,
      loading: enrollUserLoading,
      response: enrollUserResponse,
      error: enrollUserError,
    },
    unEnrollUser: {
      unEnrollUser,
      loading: unEnrollUserLoading,
      response: unEnrollUserResponse,
      error: unEnrollUserError,
    },
    markPageAsCompleted: {
      markPageAsCompleted,
      response: markPageAsCompletedResponse,
      loading: markPageAsCompletedLoading,
      error: markPageAsCompletedError,
    },
    markTaskAsCompleted: {
      markTaskAsCompleted,
      response: markTaskAsCompletedResponse,
      loading: markTaskAsCompletedLoading,
      error: markTaskAsCompletedError,
    },
  };
}

export default useQuestConsumption;
