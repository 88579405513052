import {
  FluentProvider,
  type Theme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import StickyBar from "components/StickyBar";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { cn } from "libs/classMerger";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Toaster } from "../components/Toaster";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Layout({
  children,
  themeString,
}: {
  children: React.ReactNode;
  themeString: string;
  theme?: Theme;
}) {
  const location = useLocation();
  const { userAccessState } = useUserState();
  const isSidePanelView = location.pathname.startsWith("/appInMeeting") || location.pathname.startsWith("/configure");
  const isQuestsPath = location.pathname.startsWith("/quests");
  const isStageView = location.pathname.startsWith("/stageView");
  const isInterestForm = location.pathname === "/interestform";

  const currentTheme = useMemo(() => {
    if (themeString === "dark" && isSidePanelView) return teamsDarkTheme;
    if (themeString === "contrast") return teamsHighContrastTheme;

    return {
      ...teamsLightTheme,
      colorNeutralBackground3: isSidePanelView || isStageView ? "transparent" : "#ffffff",
    };
  }, [themeString, isSidePanelView, isStageView]);

  return (
    <FluentProvider theme={currentTheme} style={{ background: tokens.colorNeutralBackground3 }}>
      <Toaster />
      {!isStageView && <Navbar />}
      <div
        className={cn(
          "flex flex-col min-h-screen max-w-[2400px] mx-auto overflow-hidden md:overflow-visible",
          isSidePanelView && "pb-11",
          isInterestForm && "bg-brown-50",
        )}
      >
        <div className={cn("flex-grow", isStageView && "flex items-center justify-center w-full")}>{children}</div>
        {!isSidePanelView && !isStageView && !isQuestsPath && <Footer />}
      </div>
      {userAccessState !== UserAccessStateEnum.Premium &&
        userAccessState !== UserAccessStateEnum.Freemium &&
        !isInterestForm && (
          <div className="fixed bottom-0">
            <StickyBar />
          </div>
        )}
    </FluentProvider>
  );
}
