import { useQuery } from "@apollo/client";
import { getAuth0Client } from "config/authConfig";
import { type ReactNode, createContext, useContext, useEffect, useReducer } from "react";
import { GET_CURRENT_ORGANIZATION } from "services/graphql/queries/index";

export enum UserAccessStateEnum {
  Loading = "loading",
  Unauthenticated = "unauthenticated",
  Freemium = "freemium",
  Premium = "premium",
}

interface OrganizationData {
  division?: string;
  logoFullUrl?: string;
  logoSquareUrl?: string;
  name: string;
  role: string;
}

interface UserStateContextProps {
  userAccessState: UserAccessStateEnum;
  organizationData: OrganizationData | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: Error | null;
}

type Action =
  | { type: "SET_LOADING" }
  | { type: "SET_AUTHENTICATED"; payload: OrganizationData | null }
  | { type: "SET_UNAUTHENTICATED" }
  | { type: "SET_ERROR"; payload: Error };

function userStateReducer(state: UserStateContextProps, action: Action): UserStateContextProps {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Loading,
        loading: true,
        isAuthenticated: false,
      };
    case "SET_AUTHENTICATED":
      return {
        ...state,
        userAccessState: action.payload ? UserAccessStateEnum.Premium : UserAccessStateEnum.Freemium,
        organizationData: action.payload,
        isAuthenticated: true,
        loading: false,
        error: null,
      };
    case "SET_UNAUTHENTICATED":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Unauthenticated,
        organizationData: null,
        isAuthenticated: false,
        loading: false,
        error: null,
      };
    case "SET_ERROR":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Unauthenticated,
        isAuthenticated: false,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

const initialState: UserStateContextProps = {
  userAccessState: UserAccessStateEnum.Loading,
  organizationData: null,
  isAuthenticated: false,
  loading: true,
  error: null,
};

export const UserStateContext = createContext<UserStateContextProps | undefined>(undefined);

interface UserStateProviderProps {
  children: ReactNode;
}

export const UserStateProvider = ({ children }: UserStateProviderProps) => {
  const [state, dispatch] = useReducer(userStateReducer, initialState);
  const {
    loading,
    data: orgData,
    error,
  } = useQuery(GET_CURRENT_ORGANIZATION, {
    skip: !state.isAuthenticated,
  });

  useEffect(() => {
    const checkAuthentication = async () => {
      dispatch({ type: "SET_LOADING" });

      try {
        const auth0 = await getAuth0Client();
        const authenticated = await auth0.isAuthenticated();

        if (authenticated) {
          dispatch({ type: "SET_AUTHENTICATED", payload: null });
        } else {
          dispatch({ type: "SET_UNAUTHENTICATED" });
        }
      } catch (err) {
        dispatch({ type: "SET_ERROR", payload: err as Error });
      }
    };

    checkAuthentication();
  }, []);

  useEffect(() => {
    const fetchOrganizationData = () => {
      if (loading) return;

      if (error) {
        dispatch({ type: "SET_ERROR", payload: error });
      } else if (orgData?.currentOrganization) {
        dispatch({
          type: "SET_AUTHENTICATED",
          payload: orgData.currentOrganization,
        });
      } else {
        dispatch({ type: "SET_AUTHENTICATED", payload: null });
      }
    };

    if (state.isAuthenticated && !loading) {
      fetchOrganizationData();
    }
  }, [state.isAuthenticated, loading, orgData, error]);

  return <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>;
};

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserStateProvider");
  }
  return context;
};
