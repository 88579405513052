import { type Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import { loadConfig } from "utils/loadEnv";
import { buildAuthUrl } from "utils/urlBuilder";

export const authConfig = {
  domain: loadConfig("REACT_APP_AUTH0_AUTH_DOMAIN"),
  redirect_uri: loadConfig("REACT_APP_START_LOGIN_PAGE_URL"),
  clientId: loadConfig("REACT_APP_AUTH0_AUTH_CLIENT_ID"),
  scope: loadConfig("REACT_APP_AUTH0_AUTH_SCOPES"),
  audience: loadConfig("REACT_APP_AUTH0_AUTH_AUDIENCE"),
  cacheLocation: "localstorage" as const,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  microsoftConnection: loadConfig("REACT_APP_AUTH0_AUTH_MICROSOFT_CONNECTION_NAME"),
};

export async function getAuth0Client(connection?: string): Promise<Auth0Client> {
  const authorizationParams = {
    audience: authConfig.audience,
    redirect_uri: authConfig.redirect_uri,
    scope: authConfig.scope,
    ...(connection ? { connection } : {}),
  };

  const auth0Instance = createAuth0Client({
    domain: authConfig.domain,
    clientId: authConfig.clientId,
    cacheLocation: authConfig.cacheLocation,
    useRefreshTokens: authConfig.useRefreshTokens,
    useRefreshTokensFallback: authConfig.useRefreshTokensFallback,
    authorizationParams,
  });
  return auth0Instance;
}

export const microsoftAuthUrl = buildAuthUrl(authConfig.redirect_uri, {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  redirect_uri: authConfig.redirect_uri,
  scope: authConfig.scope,
  audience: authConfig.audience,
  connection: authConfig.microsoftConnection,
});
